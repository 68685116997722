<template>
<div>
    <Loading v-if="loading"/>
    <div v-if="!loading">
        <v-row align="center" class=" mb-3" >
            <v-col  cols="12" md="2" v-if="($store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-add`)|| $store.state.user.userType.id == $global.state.roleType.admin) && $route.meta.type == 1">
                <v-btn @click="openAddDialog()" color="primary" large class="btn">
                    <v-icon class="ml-2">add_circle_outline</v-icon>
                    <h3>إضافة {{this.$route.meta.single}}</h3>
                </v-btn>
            </v-col>
            <v-spacer />
    
            <v-col cols="12" md="2">
                <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="search" label="الإسم" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
            </v-col>
    
            <v-col cols="12" md="2" >
                <v-autocomplete v-model="governorateId" @input="getItems()" clearable item-text="name" item-value="id" :items="$global.state.governorates" dense="" class="mr-1" label="المحافظة" :loading="$global.state.loading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="" />
            </v-col>
            
        </v-row>
        <br/>
        <v-row justify="center" align="center">
            <div class="text-center" v-if="items == null">
                <img width="300px" height="300px" src="@/assets/images/empty.png" />
                <h3>لا توجد بيانات</h3>
            </div>
            <v-col cols="12" md="3" v-for="(item,index) in items" :key="index">
                <v-card outlined class="mb-8">
                    <v-container>
                        <v-card style="margin-top:-50px" outlined width="70px" height="70px">
                            <v-avatar tile class="ma-2">
                                <v-img src="@/assets/images/warehouse.png" v-if="item.type == 1"/>
                                <img width="300px" height="300px" src="@/assets/images/delivery_van.png" v-if="item.type == 2"/>
                            </v-avatar>
                        </v-card>

                        <v-toolbar  flat>
                            <h4>
                                {{item.name}} - <span class="orange--text" v-if="item.userType == 'receiving'">(مندوب استلام)</span> <span class="teal--text" v-if="item.userType == 'delivery'">(مندوب تسليم)</span> - ( {{ item.governorate.name }} )
                            </h4>
                            <v-spacer/>
                        
                            <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-update`)|| $store.state.user.userType.id == $global.state.roleType.admin">
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="openEditDialog(item)" v-on="on" icon x-small>
                                        <v-icon color="info">
                                            fi fi-rr-edit
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>تعديل</span>
                            </v-tooltip>

                            <!-- <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-delete`) || $store.state.user.userType.id == $global.state.roleType.admin">
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="deleteItem(item.id, index)" v-on="on" icon x-small class="mr-2">
                                        <v-icon color="error">
                                            fi fi-rr-trash
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>حذف</span>
                            </v-tooltip> -->

                            
                        </v-toolbar>

                        
                        <v-divider class="mb-3"/>
                        <h5>
                            عدد الشحنات: 
                            <v-chip small label>{{ item.totalOrders }}</v-chip>
                        </h5>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </div>

    <!-- <Pagination /> -->
    <Dialog />
</div>
</template>

<script>
export default {

    components: {
        Dialog: () => import("./dialog"),
    },

    data() {
        return {
            loading: false,
            search: "",
            governorateId: "",
            headers: [
                {
                    text: "الاسم",
                    value: "name"
                },
                {
                    text: "الاجراءات",
                    value: "actions"
                }
            ],
            items: []
        }
    },

    created() {
        this.getItems()
        setTimeout(() => {
            this.$eventBus.$on(`${this.$route.path}`, () => {
                this.getItems()
            })
        }, 100);

    },

    beforeDestroy() {
        this.$eventBus.$off(`${this.$route.path}`)
    },

    methods: {
        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`warehouse?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}&Type=${this.$route.meta.type}&Name=${this.search}&GovernorateId=${this.governorateId}`)
                this.items = res.data.result
                this.$global.state.count = res.data.count
                this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getUsers() {
            this.loading = true
            try {
                let res = await this.$http.get(`User?PageIndex=1&PageSize=100&Name=${this.searchUser}`)
                this.users = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async deleteItem(id) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`Warehouse`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `تم الحذف بنجاح`,
                                type: "success",
                            });
                            this.getItems();
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `حصل خطا اثناء الحذف`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
        },

        openEditDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fill-fields')
        },
    },

}
</script>
